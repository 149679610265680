define("discourse/plugins/discourse-livestream/discourse/connectors/before-main-outlet/embeddable-chat-channel-connector", ["exports", "@glimmer/component", "@ember/controller", "@ember/service", "discourse/plugins/discourse-livestream/discourse/components/embeddable-chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _controller, _service, _embeddableChatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmbedableChatChannelConnector extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "embeddableChat", [_service.service]))();
    #embeddableChat = (() => (dt7948.i(this, "embeddableChat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topicController", [(0, _controller.inject)("topic")]))();
    #topicController = (() => (dt7948.i(this, "topicController"), void 0))();
    get shouldRender() {
      return this.embeddableChat.canRenderChatChannel(this.topicController);
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <EmbeddableChatChannel
            @chatChannelId={{this.embeddableChat.chatChannelId}}
          />
        {{/if}}
      
    */
    {
      "id": "VbeaN4Zn",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@chatChannelId\"],[[30,0,[\"embeddableChat\",\"chatChannelId\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-livestream/discourse/connectors/before-main-outlet/embeddable-chat-channel-connector.js",
      "scope": () => [_embeddableChatChannel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EmbedableChatChannelConnector;
});